<template>
  <div class="container">
    <div class="row align-items-center justify-content-center">
      <div class="col-lg-6">
        <div class="copyright text-center text-center text-muted">
          &copy; {{ year }}
          <a href="https://www.receitanet.net" class="font-weight-bold ml-1" target="_blank" :style="textColor()">
            ReceitaNet.net
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ReceitaNetCopyRight",
  props: ['white'],
  data() {
    return {
      year: (new Date).getFullYear(),
    }
  },
  methods: {
    textColor: function () {
      if (this.white) {
        return {
          'color': '#6c757d !important',
        }
      } else {
        return {
          'color': '#6c757d !important',
        }
      }
    }
  }
}
</script>
