<template>
  <router-view></router-view>
</template>

<script>
export default {}
</script>

<style>
.list-group-item {
  border-bottom: 2px solid rgba(0, 0, 0, 0.125);
}

.list-group-item:last-child {
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
}

.modal-backdrop {
  opacity: .5;
}
</style>
