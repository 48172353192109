import NotFound from "@/views/NotFound"
import DashboardLayout from "@/views/Layout/DashboardLayout"
import LoginLayout from "@/views/LoginLayout/LoginLayout"

const Index = () => import(/* webpackChunkName: "central" */'@/views/Index')
const Home = () => import(/* webpackChunkName: "central" */'@/views/Home')
const Cobrancas = () => import(/* webpackChunkName: "central" */'@/views/Cobrancas')
const GraficoConsumo = () => import(/* webpackChunkName: "central" */'@/views/GraficoConsumo')
const Chamados = () => import(/* webpackChunkName: "central" */'@/views/Chamados')
const Contrassenha = () => import(/* webpackChunkName: "central" */'@/views/Contrassenha')
const Login = () => import(/* webpackChunkName: "central" */'@/views/Login')
const LoginAuto = () => import(/* webpackChunkName: "central" */'@/views/LoginAuto')
const LoginCadastro = () => import(/* webpackChunkName: "central" */'@/views/LoginCadastro')
const ContrassenhaValidar = () => import(/* webpackChunkName: "central" */'@/views/ContrassenhaValidar')

const routes = [
    {
        path: '/',
        redirect: 'index',
        component: LoginLayout,
        children: [
            {path: '/', name: 'index', component: Index,},
            {path: '/login', name: 'login', component: Login,},
            {path: '/login/:username/:password', component: LoginAuto,},
            {path: '/login/:cpfcnpj', component: LoginAuto,},
            {path: '/cadastro', name: 'cadastro', component: LoginCadastro,},
            {path: '/validar', name: 'contrassenha-validar', component: ContrassenhaValidar,},
        ],
    },
    {
        path: '/',
        component: DashboardLayout,
        children: [
            {path: '/home', name: 'home', component: Home,},
            {path: '/cobrancas', name: 'cobrancas', component: Cobrancas,},
            {path: '/grafico-consumo', name: 'grafico-consumo', component: GraficoConsumo,},
            {path: '/chamados', name: 'chamados', component: Chamados,},
            {path: '/contrassenha', name: 'contrassenha', component: Contrassenha,},
        ],
    },
    {path: '*', component: NotFound},
]

export default routes