import Vue from 'vue'
import VueMask from 'vue-the-mask'
import App from './App'
import DashboardPlugin from '@/plugins/dashboard-plugin'
import router from '@/routes/router'
import axios from 'axios'
import ChartJS from 'chart.js'
import VueClipboard from 'vue-clipboard2'

// Vue.config.productionTip = false
Vue.prototype.$axios = axios
window.Chart = ChartJS
Vue.use(DashboardPlugin)
Vue.use(VueMask)
VueClipboard.config.autoSetContainer = true
Vue.use(VueClipboard)

new Vue({
    el: '#app',
    render: h => h(App),
    router,
    created() {
        this.$router.beforeResolve((to, from, next) => {
            if (this.accessToken) {
                axios.defaults.headers['Authorization'] = 'Bearer ' + this.accessToken
            }

            next()
        })
    },
    data: function () {
        return {
            // accessToken: null,
            empresa: {
                cancelada: false,
                id: 0,
                nome: null,
                is_login_cpf: false,
                is_login_username: false,
                cpfcnpj_contrassenha: false,
                cpfcnpj_contrassenha_obrigatorio: false,
                banner_logo: false,
                configs: {
                    theme: 'blueheader',
                    theme_header_left: '#5e72e4',
                    theme_header_right: '#5e72e4',
                    theme_header_text_color: '#ffffff',
                    theme_button_historico_pagamentos: '#6c757d',
                    theme_background: '#f8f9fe',
                    theme_bg_visualizar_chamados: '#172b4d',
                    theme_bg_grafico_consumo: '#172b4d',
                    theme_login: 'blue',
                    theme_login_background: '#172b4d',
                    theme_login_header_text_color: '#FFFFFF',
                    theme_login_header_left: '#5e72e4',
                    theme_login_header_right: '#825ee4',
                    '2via_boleto': true,
                    notificacao_pagamento: true,
                    historico_pagamento: true,
                    grafico_consumo: true,
                    planos_cobrancas: true,
                    dados_cadastrais: true,
                    chamados: true,
                    chamados_counts: 3,
                    chamados_multiple: 1,
                    chamados_resposta: true,
                    materiais: true,
                    contrato: true,
                    consumos_mensal: true,
                    mensagem: 'Seja Bem-vindo a sua Central do Assinante.',
                    mensagem_notificacao_pagamento: 'Confirmo pagamento(s) da(s) pendência(s).',
                    app_web: 'http://applink.com.br/receitanet',
                    app_android: 'marke://details?id=br.com.app.gpu1926782.gpuc8113c6be16f2086315c679a87f9c938',
                    app_ios: null,
                    facebook: null,
                    twitter: null,
                    instagram: null,
                    whatsapp: null,
                },
            },
            name: '',
            isContrassenha: false,
        }
    },
    methods: {
        getMobileOS: function () {
            var userAgent = navigator.userAgent || navigator.vendor || window.opera;

            if (userAgent.match(/iPad/i) || userAgent.match(/iPhone/i) || userAgent.match(/iPod/i)) {
                return 'iOS';
            } else if (userAgent.match(/Android/i)) {
                return 'Android';
            } else {
                return 'unknown';
            }
        },
        detectContrast: function (color) {
            let rgb = [
                parseInt(color.substring(1, 3), 16),
                parseInt(color.substring(3, 5), 16),
                parseInt(color.substring(5, 7), 16),
            ];
            const brightness = Math.round(((parseInt(rgb[0]) * 299) +
                (parseInt(rgb[1]) * 587) +
                (parseInt(rgb[2]) * 114)) / 1000);
            return (brightness > 125) ? 'black' : 'white';
        },
    }
})