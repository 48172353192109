<template>
  <div class="header bg-primary pb-6" :style="$parent.styleHeader">
    <div class="container">
      <div class="header-body">
        <div class="row align-items-center pb-4">
          <div class="col-lg-5 col-md-4 col-12">
            <nav aria-label="breadcrumb">
              <ol class="breadcrumb breadcrumb-links breadcrumb-dark mb-0">
                <li class="breadcrumb-item">
                  <router-link :to="{name: 'home'}"><i class="fas fa-home"></i></router-link>
                </li>
                <li class="breadcrumb-item" v-for="(breadcrumb, idx) in breadcrumbs" :key="idx">
                  <router-link :to="breadcrumb.to">{{ breadcrumb.title }}</router-link>
                </li>
              </ol>
            </nav>
          </div>
          <div class="col-lg-7 col-12 col-md-8 mb--2 social-networks">
            <a :href="$root.empresa.configs.facebook" class="btn btn-facebook mt-1 mb-1" v-if="$root.empresa.configs.facebook"
               target="_blank">
              <i class="fab fa-facebook-square"></i>
              Facebook
            </a>
            <a :href="$root.empresa.configs.twitter" class="btn btn-twitter mt-1 mb-1" v-if="$root.empresa.configs.twitter"
               target="_blank">
              <i class="fab fa-twitter"></i>
              Twitter
            </a>
            <a :href="$root.empresa.configs.instagram" class="btn btn-instagram mt-1 mb-1" v-if="$root.empresa.configs.instagram"
               target="_blank">
              <i class="fab fa-instagram"></i>
              Instagram
            </a>
            <a :href="urlWhatsApp()" class="btn btn-whatsapp mt-1 mb-1" v-if="$root.empresa.configs.whatsapp"
               target="_blank">
              <i class="fab fa-whatsapp"></i>
              WhatsApp
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "DashboardHeader",
  data: function () {
    return {
      breadcrumbs: [{
        title: 'Principal',
        to: {name: 'home'},
      }],
    }
  },
  // watch: {
  //   '$root.empresa': function () {
  //     // this.styleHeaderColorLeft =
  //     // this.styleHeaderColorRight =
  //     // this.styleHeaderTextColor =
  //     this.styleHeaderBackground = this.$root.empresa.configs.theme_background + ' !important'
  //     this.styleHeaderBgDefault = this.$root.empresa.configs.theme_bg_default + ' !important'
  //   },
  // },
  created() {
    if (!this.$root.accessToken) {
      this.$router.push('login')
    }
    this.$root.$on('breadcrumb.push', (title, to) => {
      this.breadcrumbs.push({
        title,
        to,
      })
    })
    this.$root.$on('breadcrumb.pop', () => {
      this.breadcrumbs.pop()
    })
    this.$root.$on('breadcrumb.reset', () => {
      this.breadcrumbs = [{
        title: 'Principal',
        to: {name: 'home'},
      }]
    })
  },
  methods: {
    urlWhatsApp: function () {
      if (this.$root.empresa.configs.whatsapp) {
        return 'https://wa.me/+55' + this.$root.empresa.configs.whatsapp.replace(/[^0-9]/g, '') //+ '&text&app_absent=0'
      } else {
        return null
      }
    }
  },
}
</script>

<style scoped>
.social-networks {
  margin-top: 1em;
}

@media (min-width: 768px) {
  .social-networks {
    margin-top: 0px;
    text-align: right !important;
  }
}

.btn-facebook, .btn-twitter, .btn-instagram, .btn-whatsapp {
  padding: 5px 10px;
}

.btn-facebook {
  color: #fff;
  border-color: #3b5999;
  background-color: #3b5999;
  font-weight: 600;
}

.btn-twitter {
  color: #fff;
  border-color: #1da1f2;
  background-color: #1da1f2;
  font-weight: 600;
}

.btn-instagram {
  color: #fff;
  border-color: #e4405f;
  background-color: #e4405f;
  font-weight: 600;
}

.btn-whatsapp {
  color: #fff;
  border-color: #00897b;
  background-color: #00897b;
  font-weight: 600;
}
</style>