<template>
  <div class="container mt--6">
    <div class="row">
      <div class="col-sm-12">
        <div class="container" v-if="isLoad">
          <div class="row row-cols-1">
            <div class="col text-center">
              <span class="fa fa-refresh fa-spin text-white"></span>
            </div>
            <div class="col text-center">
              <span class="text-white">Carregando...</span>
            </div>
          </div>
        </div>

        <router-view></router-view>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "DashboardContent",
  created() {
    this.$on('loading', () => {
      this.isLoad = true
    })
    this.$on('loaded', () => {
      this.isLoad = false
    })
  },
  data: function () {
    return {
      isLoad: true,
    }
  },
}
</script>

<style scoped>

</style>