<template>
  <div :style="styleBackground" class="bg-default g-sidenav-show g-sidenav-pinned">
    <div class="main-content">
      <div :style="styleHeader" class="header bg-gradient-primary py-6 py-lg-6 pt-lg-6">
        <div class="separator separator-bottom separator-skew zindex-100 mt--5">
          <svg preserveAspectRatio="none" version="1.1" viewBox="0 -1 2560 100" x="0" xmlns="http://www.w3.org/2000/svg"
               y="0">
            <polygon :style="styleBackground" class="fill-default" points="2560 0 2560 100 0 100"></polygon>
          </svg>
        </div>
      </div>
      <div class="container pb-3">
        <div class="row justify-content-center">
          <div v-if="isLoad" class="col-12">
            <div class="text-center mt--3 mb-5">
              <i class="fa fa-refresh fa-spin"></i>
            </div>
          </div>
          <router-view class="col-lg-5 col-md-7 mt-5"></router-view>
        </div>
      </div>
      <receita-net-copy-right :white="true" class="pb-2"></receita-net-copy-right>
    </div>
  </div>
</template>

<script>
import ReceitaNetCopyRight from "@/components/ReceitaNetCopyRight";

export default {
  name: "LoginLayout",
  components: {ReceitaNetCopyRight},
  data: function () {
    return {
      isLoad: true,
      error: false,

      styleLoginBackgroundColor: false,
      styleHeaderTextColor: false,
      styleHeaderColorLeft: false,
      styleHeaderColorRight: false,
    }
  },
  computed: {
    styleBackground: function () {
      return {
        'background-color': this.styleLoginBackgroundColor,
        'min-height': '100%',
        'fill': this.styleLoginBackgroundColor,
      }
    },
    styleHeader: function () {
      return {
        background: 'linear-gradient(87deg, ' + this.styleHeaderColorLeft + ' 0, ' + this.styleHeaderColorRight + ' 100%) !important'
      }
    },
    styleHeaderText: function () {
      return {
        color: this.styleHeaderTextColor,
      }
    },
  },
  watch: {
    '$root.empresa': function (value) {
      this.styleLoginBackgroundColor = value.configs.theme_login_background + ' !important'
      this.styleHeaderColorLeft = value.configs.theme_login_header_left
      this.styleHeaderColorRight = value.configs.theme_login_header_right
      this.styleHeaderTextColor = value.configs.theme_login_header_text_color + ' !important'
    }
  },
  methods: {
    loadEmpresa: function (callback = null, idEmpresa = null) {
      let params = {}
      if (idEmpresa > 0) {
        params['idEmpresa'] = idEmpresa;
      } else {
        let hostname = window.location.hostname
        let match = hostname.match(/^([a-z0-9-_]*)\.(receitanet|centralassinante)/)

        if (match) {
          params['subdomain'] = match[1]
        }
      }

      this.$axios.get(process.env.VUE_APP_CENTRAL_API_URL + 'empresa', {
        params
      })
          .then(data => {
            this.$root.empresa = data.data
            this.isLoad = false

            if (callback) {
              callback()
            }
          })
    },
    authenticate: function (data) {
      this.$axios.post(process.env.VUE_APP_CENTRAL_API_URL + 'token', data)
          .then(res => {
            if (res.data.access_token !== undefined) {
              this.$root.accessToken = res.data.access_token
              this.$root.name = res.data.name
              this.$root.isContrassenha = res.data.isContrassenha
              this.$axios.defaults.headers['Authorization'] = 'Bearer ' + res.data.access_token

              if (!this.$root.empresa.id) {
                this.loadEmpresa(function () {
                  this.redirectAuthenticated()
                }.bind(this))
              } else {
                this.redirectAuthenticated()
              }
            } else if (res.data.multiploCadastro === true) {
              this.$router.push({
                name: 'cadastro', params: {
                  data: data,
                  contratos: res.data.contratos,
                }
              })
            }
          })
          .catch(() => {
            this.error = 'Falha na autenticação. Verifique.'
          })
    },
    redirectAuthenticated: function () {
      let fnc = function () {
        if (this.$root.empresa.is_login_cpf
            && this.$root.empresa.cpfcnpj_contrassenha
            && this.$root.empresa.cpfcnpj_contrassenha_obrigatorio) {
          if (this.$root.isContrassenha === true) {
            // VALIDAR CONTRASSENHA
            this.$router.push({name: 'contrassenha-validar', params: {validar: true}})
          } else {
            // DEFINIR CONTRASSENHA
            this.$router.push({
              name: 'contrassenha', params: {
                is_definir_contrassenha: true
              }
            })
          }
        } else if (this.$root.isContrassenha) {
          // VALIDAR CONTRASSENHA
          this.$router.push({name: 'contrassenha-validar', params: {validar: true}})
        } else {
          this.$router.push('home')
        }
      }.bind(this)
      if (this.$root.empresa.id) {
        fnc()
      } else {
        this.loadEmpresa(function () {
          fnc()
        }.bind(this))
      }
    },
  },
}
</script>

<style>
html, body {
  height: 100%;
}
</style>
<style scoped>
.fa-refresh {
  font-size: 5em;
  color: white;
}
</style>
