<template>
  <div :style="$parent.styleHeader" class="navbar navbar-top navbar-expand navbar-dark bg-primary">
    <div ref="container" class="container pl-3">
      <router-link :style="styleBrand" :to="{name: 'home'}" class="navbar-brand">
        <img v-if="$root.empresa.banner_logo != false && $root.empresa.configs.header_logotipo"
             :src="$root.empresa.banner_logo" alt="Logotipo da Empresa"
             style="max-height: 50px;">
        <span
            v-if="$root.empresa.configs.header_titulo"
            :class="{'d-none': $root.empresa.banner_logo != false, 'd-md-inline-block': $root.empresa.banner_logo != false}">{{
            nomeEmpresa
          }}</span>
      </router-link>
      <div ref="userProfile" class="navbar-nav align-items-center ml-auto ml-md-0">
        <div class="nav-item dropdown">
          <a class="nav-link btn btn-default" data-toggle="dropdown" href="" style="min-width: 150px;">
            <div class="media align-items-center">
              <div class="media-body ml-2">
                <span class="mb-0 text-sm  font-weight-bold">{{ name }}</span>
              </div>
            </div>
          </a>
          <div class="dropdown-menu dropdown-menu-right">
            <a v-if="$root.empresa.cpfcnpj_contrassenha" class="dropdown-item" href="#"
               @click.prevent="clickContrassenha">{{ menuContrassenha }}</a>
            <a v-if="$root.empresa.configs.dados_cadastrais && dadosCadastrais.nome !== undefined" class="dropdown-item"
               href="#"
               @click.prevent="clickDadosCadastrais">Dados Cadastrais</a>
            <a class="dropdown-item" href="#" @click.prevent="clickSair">Sair</a>
          </div>
        </div>
        <dados-cadastrais v-if="$root.empresa.configs.dados_cadastrais && dadosCadastrais.nome !== undefined"
                          :dados-cadastrais="dadosCadastrais"></dados-cadastrais>
      </div>
    </div>
  </div>
</template>

<script>
import DadosCadastrais from "@/components/DadosCadastrais";

export default {
  name: "DashboardNavbar",
  components: {DadosCadastrais},
  data: function () {
    return {
      dadosCadastrais: {},

      widthUserProfile: 0,
      widthContainer: 0,
    }
  },
  created() {
    this.$root.$on('dados_cadastrais', (dadosCadastrais) => {
      this.dadosCadastrais = dadosCadastrais
    })
  },
  computed: {
    nomeEmpresa: function () {
      if (this.$root.empresa && this.$root.empresa.nome) {
        return this.$root.empresa.nome
      }
      return '-'
    },
    name: function () {
      let i = this.$root.name.indexOf(' ')
      if (i > 10) {
        i = 10;
      }
      return this.$root.name.substring(0, i)
    },
    styleBrand: function () {
      let style = this.$parent.styleHeaderTextColor;
      if (this.widthUserProfile > 0 && this.widthContainer > 0) {
        style['max-width'] = (this.widthContainer - this.widthUserProfile) + "px"
        style['overflow'] = 'hidden'
      }

      return style
    },
    menuContrassenha: function () {
      if (this.$root.isContrassenha) {
        return 'Alterar Contrassenha'
      } else {
        return 'Criar Contrassenha'
      }
    },
  },
  mounted() {
    this.widthContainer = this.$refs['container'].offsetWidth
    this.widthUserProfile = this.$refs['userProfile'].offsetWidth
  },
  methods: {
    clickSair: function () {
      this.$root.accessToken = false
      this.$root.name = ''
      delete this.$axios.defaults.headers['Authorization']

      this.$router.push('login')
    },
    clickContrassenha: function () {
      this.$router.push('contrassenha')
    },
    clickDadosCadastrais: function () {
      this.$root.$emit('dados_cadastrais.open')
    },
  },
}
</script>

<style scoped>
.navbar-top .navbar-brand {
  display: block;
  color: #ffffff;
}

/*.navbar-brand {*/
/*  max-width: 500px;*/
/*  overflow: hidden;*/
/*  !*white-space: nowrap;*!*/
/*  !*text-overflow: ellipsis;*!*/
/*}*/
</style>