<template>
  <footer class="footer pt-0" :style="$parent.styleBackground">
    <receita-net-copy-right></receita-net-copy-right>
  </footer>
</template>

<script>
import ReceitaNetCopyRight from "@/components/ReceitaNetCopyRight";

export default {
  name: "DashboardFooter",
  components: {ReceitaNetCopyRight},
}
</script>

<style scoped>

</style>