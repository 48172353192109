<template>
  <div class="wrapper" :style="styleBackground">
    <div class="main-content" id="panel">
      <dashboard-navbar></dashboard-navbar>
      <dashboard-header></dashboard-header>
      <dashboard-content></dashboard-content>
      <dashboard-footer></dashboard-footer>
    </div>
  </div>
</template>

<script>
import {DashboardContent, DashboardFooter, DashboardHeader, DashboardNavbar} from "@/views/Layout";

export default {
  name: "DashboardLayout",
  components: {
    DashboardNavbar,
    DashboardHeader,
    DashboardContent,
    DashboardFooter,
  },
  computed: {
    styleHeader: function () {
      return {
        background: this.$root.empresa.configs.theme_header_left && this.$root.empresa.configs.theme_header_right ? 'linear-gradient(87deg, ' + this.$root.empresa.configs.theme_header_left + ' 0, ' + this.$root.empresa.configs.theme_header_right + ' 100%) !important' : false,
      }
    },
    styleHeaderTextColor: function () {
      return {
        color: this.$root.empresa.configs.theme_header_text_color + ' !important',
      }
    },
    styleBackground: function () {
      return {
        'background-color': this.$root.empresa.configs.theme_background + ' !important',
        'min-height': '100%',
      }
    },
    styleBgDefault: function () {
      return {
        'background-color': this.$root.empresa.configs.theme_bg_default + ' !important',
      }
    },
  },
}
</script>

<style scoped>

</style>