<template>
  <b-modal ref="dados_cadastrais" title="Dados Cadastrais">
    <div v-if="dadosCadastrais && dadosCadastrais.nome" class="list-group ml--4 mr--4 mb--4 mt--4">
      <div class="list-group-item">
        <strong>Nome:</strong> {{ dadosCadastrais.nome }}
      </div>
      <div class="list-group-item">
        <strong>CPF/CNPJ:</strong> {{ dadosCadastrais.cpfcnpj }}
      </div>
      <div class="list-group-item">
        <strong>RG/IE:</strong> {{ dadosCadastrais.inscricao }}
      </div>
      <div class="list-group-item">
        <strong>Email:</strong> {{ dadosCadastrais.email }}
      </div>
      <div class="list-group-item">
        <strong>Data de Nascimento:</strong> {{ dadosCadastrais.data_nascimento }}
      </div>
      <div class="list-group-item">
        <strong>Endereço:</strong> {{ dadosCadastrais.endereco }}
      </div>
      <div class="list-group-item">
        <strong>Complemento:</strong> {{ dadosCadastrais.complemento }}
      </div>
      <div class="list-group-item">
        <strong>Bairro:</strong> {{ dadosCadastrais.bairro }}
      </div>
      <div class="list-group-item">
        <strong>Cidade:</strong> {{ dadosCadastrais.cidade }}
      </div>
      <div class="list-group-item">
        <strong>UF:</strong> {{ dadosCadastrais.uf }}
      </div>
    </div>
    <button slot="modal-footer" class="btn btn-secondary" type="button" @click="clickClose">Fechar</button>
  </b-modal>
</template>

<script>
export default {
  name: "DadosCadastrais",
  props: ['dadosCadastrais'],
  created() {
    this.$root.$on('dados_cadastrais.open', () => {
      this.$refs['dados_cadastrais'].show()
    })
  },
  methods: {
    clickClose: function () {
      this.$refs['dados_cadastrais'].hide()
    },
  },
}
</script>

<style scoped>
.list-group, .list-group-item {
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
  border-left: inherit;
  border-right: inherit;
}
</style>
