import {
    BAlert,
    BButton,
    BButtonToolbar,
    BCard,
    BCardGroup,
    BCardImg,
    BForm,
    BFormGroup,
    BFormInput,
    BModal
} from 'bootstrap-vue'
import VueRouter from 'vue-router'

import "assets/scss/bootstrap.scss"

export default {
    install(Vue) {
        Vue.component('BAlert', BAlert)
        Vue.component('BButton', BButton)
        Vue.component('BCard', BCard)
        Vue.component('BCardImg', BCardImg)
        Vue.component('BCardGroup', BCardGroup)
        Vue.component('BModal', BModal)
        Vue.component('BForm', BForm)
        Vue.component('BFormGroup', BFormGroup)
        Vue.component('BFormInput', BFormInput)
        Vue.component('BButtonToolbar', BButtonToolbar)
        Vue.use(VueRouter)
    }
}